<template>
	<div>

		<LandingpageHeader></LandingpageHeader>


		<div class="landingpage__content">
			<h1>Datenschutz­erklärung</h1>
			<p class="large">Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.</p>
			<h2>1. Datenschutz auf einen Blick</h2>
			<h4>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>
			<p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.</p>
			<h4>Wie erfassen wir Ihre Daten?</h4>
			<p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben.</p>
			<p>Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.</p>
		</div>
				

		<LandingpageFooter></LandingpageFooter>

	</div>
</template>

<script>
	import LandingpageHeader from "@/components/LandingpageHeader.vue";
	import LandingpageFooter from "@/components/LandingpageFooter.vue";

	export default {
		name: 'LandingpageAGB',
		components: { LandingpageHeader, LandingpageFooter },
	}

</script>

<style lang="scss">

	.landingpage__content {
		max-width: 1088px;
		margin: 0px auto;
		padding: 96px 24px 160px !important;

		h1 {
			margin-bottom: 32px;
		}

		h2 {
			margin-bottom: 24px;
		}

		h4 {
			margin-bottom: 8px;
		}

		.large {
			font-size: 17px;
			line-height: 28px;
			margin-bottom: 32px;
		}
	}



	/* Remove if it's possible to trigger it only for landingpage */
	.v-main.landingpage {
		max-width: inherit !important;
		padding: 0 !important;
	}

	.landingpage {
		background: $colorBackground;
	}
</style>